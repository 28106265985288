export const getCustomStyles = {
  container: (styles, state) => {
    if (state.selectProps?.showOnlySelectedValues) {
      return {
        ...styles,
        pointerEvents: 'auto',
      }
    }
    return styles
  },
  control: (styles, state) => {
    if (state.selectProps?.showOnlySelectedValues) {
      return {
        margin: '-5px',
      }
    }

    return {
      ...styles,
      borderColor: state.isFocused ? 'var(--bs-primary)' : styles.borderColor,
      borderRadius: 0,
      boxShadow: state.isFocused
        ? '0 0 8px rgba(var(--bs-primary-rgb), .6 )'
        : 'none',
      '&:hover': {
        borderColor: 'var(--bs-primary)',
      },
      minHeight: '34px',
    }
  },
  dropdownIndicator: (styles) => {
    return { ...styles, padding: '4px' }
  },
  clearIndicator: (styles) => {
    return { ...styles, padding: '4px' }
  },
  groupHeading: (styles) => {
    return {
      ...styles,
      fontSize: '80%',
      marginBottom: 0,
    }
  },
  indicatorsContainer: (styles, state) => {
    if (state.selectProps?.showOnlySelectedValues) {
      return {
        display: 'none',
      }
    }
    return styles
  },
  multiValue: (styles, state) => {
    if (state.selectProps?.showOnlySelectedValues) {
      return {
        ...styles,
        background: 'none',
        border: '1px solid var(--fz-secondary-border)',
        paddingRight: '4px',
        pointerEvents: 'true',
      }
    }
    return styles
  },
  multiValueLabel: (styles, { data }) => {
    return {
      ...styles,
      cursor: 'pointer',
      whiteSpace: 'normal',
    }
  },
  multiValueRemove: (styles, state) => {
    if (state.selectProps?.showOnlySelectedValues) {
      return {
        display: 'none',
      }
    }
    return styles
  },
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 10,
    }
  },
  option: (styles, { data, isFocused, isSelected }) => ({
    ...styles,
    ':active': {
      backgroundColor: 'rgba(var(--bs-primary-rgb), .3)',
    },
    padding: '4px 8px',
    backgroundColor: isSelected
      ? 'var(--bs-primary)'
      : isFocused
      ? 'var(--bs-gray-200)'
      : styles.backgroundColor,
    paddingLeft: `${12 + data.level * 12}px`,
    maxWidth: '100%',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: ' break-spaces',
  }),
  valueContainer: (styles, { hasValue, selectProps }) => {
    if (selectProps?.showOnlySelectedValues) {
      return { ...styles, padding: 0 }
    }
    return {
      ...styles,
      ...(hasValue && { paddingLeft: '4px' }),
      paddingRight: 0,
    }
  },
}
