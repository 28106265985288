import PropTypes from 'prop-types'
import React, { Fragment, useState, useEffect, forwardRef } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import Form from 'react-formal'
import Button from 'components/Button'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import * as yup from 'yup'

import styles from './styles.module.scss'

import messages from './messages'
import FormField from 'components/FormField'

const propTypes = {
  onClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  deleteModalMessage: PropTypes.string,
  itemType: PropTypes.string,
  size: PropTypes.string,
  deleteButtonContent: PropTypes.element,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  stopPropagation: PropTypes.bool,
}

const deleteModalSchema = () =>
  yup.object({
    deleteConfirmation: yup.boolean().default(() => false),
  })

const DeleteButtonWithConfirmation = forwardRef(
  (
    {
      children,
      className = 'btn-danger',
      deleteButtonContent,
      deleteModalMessage,
      deleteModalButtonContent,
      itemType,
      onCancel = () => undefined,
      onClick,
      size = 'default',
      stopPropagation,
      useCheckbox,
      ...rest
    },
    ref
  ) => {
    const [showModal, setshowModal] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    useEffect(() => {
      // When the modal is opened, set deleteConfirmation to false
      if (showModal) {
        setForm({ deleteConfirmation: false })
        setConfirmDelete(false)
      }
    }, [showModal])

    const scheme = deleteModalSchema()

    const [form, setForm] = useState(scheme)

    const resolveModalMessage = (deleteModalMessage, itemType) => {
      if (deleteModalMessage) {
        return deleteModalMessage
      }
      return itemType ? (
        <FormattedMessage
          {...messages.deleteModalDefaultMessageWithItemType}
          values={{ itemType }}
        />
      ) : (
        <FormattedMessage {...messages.deleteModalDefaultMessage} />
      )
    }

    return (
      <Fragment>
        <Button
          className={classNames(className, size && `btn-${size}`)}
          onClick={(event) => {
            if (stopPropagation) event.stopPropagation()
            setshowModal(true)
          }}
          {...rest}
          ref={ref}
        >
          {deleteButtonContent || <FormattedMessage {...messages.delete} />}{' '}
          {children}
        </Button>
        <Modal show={showModal} onHide={() => setshowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {deleteModalButtonContent ? (
                deleteModalButtonContent
              ) : itemType ? (
                <FormattedMessage
                  {...messages.deleteModalTitleWithItemType}
                  values={{ itemType }}
                />
              ) : (
                <FormattedMessage {...messages.deleteModalTitle} />
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2>{resolveModalMessage(deleteModalMessage, itemType)}</h2>
            {useCheckbox && (
              <Row className={styles.deleteConfirmCheckbox}>
                <Col md={1}>
                  <Form
                    schema={scheme}
                    value={form}
                    onChange={(form) => setForm(form)}
                  >
                    <FormField
                      aria-label="Delete confirmation"
                      name="deleteConfirmation"
                      type="boolean"
                      onChange={() => setConfirmDelete(!confirmDelete)}
                    />
                  </Form>
                </Col>
                <Col md={11}>
                  <h5>I understand</h5>
                </Col>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setshowModal(false)
                onCancel()
              }}
            >
              <FormattedMessage {...messages.cancel} />
            </Button>
            <Button
              className="btn-danger"
              onClick={() => {
                setshowModal(false)
                onClick()
              }}
              disabled={useCheckbox && !confirmDelete}
            >
              {deleteModalButtonContent || (
                <FormattedMessage {...messages.delete} />
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    )
  }
)

DeleteButtonWithConfirmation.propTypes = propTypes

export default DeleteButtonWithConfirmation
