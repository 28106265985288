/*
 *
 * Role constants
 *
 */

export const CREATE_ROLE_CLAIM = 'app/Role/CREATE_ROLE_CLAIM'
export const CREATE_ROLE_CLAIM_ERROR = 'app/Role/CREATE_ROLE_CLAIM_ERROR'
export const CREATE_ROLE_CLAIM_SUCCESS = 'app/Role/CREATE_ROLE_CLAIM_SUCCESS'
export const DELETE_ROLE_CLAIM = 'app/Role/DELETE_ROLE_CLAIM'
export const DELETE_ROLE_CLAIM_ERROR = 'app/Role/DELETE_ROLE_CLAIM_ERROR'
export const DELETE_ROLE_CLAIM_SUCCESS = 'app/Role/DELETE_ROLE_CLAIM_SUCCESS'

export const GET_ROLE_CLAIMS = 'app/Role/GET_ROLE_CLAIMS'
export const GET_ROLE_CLAIMS_ERROR = 'app/Role/GET_ROLE_CLAIMS_ERROR'
export const GET_ROLE_CLAIMS_SUCCESS = 'app/Role/GET_ROLE_CLAIMS_SUCCESS'
export const TOGGLE_SHOW_CREATE_ROLE_CLAIM_FORM =
  'app/Role/TOGGLE_SHOW_CREATE_ROLE_CLAIM_FORM'

export const PERMISSION_CATEGORIES = {
  budgets: 'budgets',
  dimensionValues: 'dimensionValues',
  reportSchemes: 'reportsSchemes',
}

export const ASC = 'asc'
export const DESC = 'desc'
export const CLAIM_TYPES = ['read', 'edit', 'delete', 'create']
export const REPORT_READER = 'ReportReader'

export const COMPANY_USER = 'CompanyUser'
export const COMPANY_SUPER_USER = 'CompanySuperUser'
export const CUSTOMER_USER = 'CustomerUser'
export const CUSTOMER_SUPER_USER = 'CustomerSuperUser'
export const SYSTEM_USER = 'SystemUser'
export const SYSTEM_SUPER_USER = 'SystemSuperUser'

export const FINAZILLA_ROLES = [
  COMPANY_USER,
  COMPANY_SUPER_USER,
  CUSTOMER_USER,
  CUSTOMER_SUPER_USER,
  SYSTEM_USER,
  SYSTEM_SUPER_USER,
]

export const COMPANY_USER_DEFAULT_CRUD_CLAIMS = [
  'accountSchemes',
  'accountingPeriods',
  'rollingRuleTemplates',
  'notifications',
]
export const COMPANY_USER_DEFAULT_READ_CLAIMS = [
  'AccountSchemesRead',
  'AccountingPeriodsRead',
  'RollingRuleTemplatesRead',
  'NotificationRead',
]

export const COMPANY_USER_DEFAULT_CLAIMS = [
  'Accounts',
  'AccountingPeriodsRead',
  'AccountSchemesRead',
  'AccountSchemeTemplates',
  'CalculationConstants',
  'RollingRuleTemplatesRead',
]
